import React from 'react';
import { GiHastyGrave } from 'react-icons/gi';
import './land.css';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';

const Tribute = () => {
  return (
    <div className="tribute-display ">
            <nav className='app__navbar bg-gray-400'>
        <Link to="/tributes">
            <button className=' bg-gray-700 text-gray-100 custom__button'>  Post a tribute</button>
        </Link>
        <Link to="/home">
            <button className=' bg-gray-700 text-gray-100 ml-10 custom__button'>  Home</button>
        </Link>
    </nav>
    <div className="rowe">
        <div className="rowe-child border-gray-700">
            <div className="rowe-item">
                <GiHastyGrave className="grave-name text-gray-100 bg-gray-700" />
                <h3>Chinonye Emmanuel Ebuzor</h3>
                <h6>Son</h6>
                <p>You were a wonderful gift of love I was privileged to have as a mother. A rare gift from God that was given to impact lives on Earth in so many ways. You stood out like a collossus in every area both in your family  and business life. You effectively combined your trading business, farming and the task of training of us both your biological children and the others without much stress .You trained us your children with the fear of God making us to imbibe the virtues of honesty , hard work, courage  and to accept the will of God in every area of our lives. You were there to help the needy encourage the less privileged and give succour to the helpless. You stood in the gap so strongly that our father's absence was not so strongly felt when it came to paying our school fees when he was away on business trips and could not be reached immediately. You made so much sacrifice that paid off in various areas in our lives that when children were suffering from serious malnutrition during the cilvil war those of us around had sufficient nutrients that shielded us from the dreaded disease. Your love for me and my family will not be forgotten in a hurry. I will also not forget your able demonstration of a strong character in all you did. You stood to be counted for the truth not minding whose ox is goored. You were not attracted to material things when it mattered to say the truth and you made me to toe that line and I am happy I learnt this rope from you. I will not forget your strong sense of humour and witty presentation of matters in a hurry. I will forever be very grateful to God for giving me a mother like you. I miss you greatly dearest Mum. Rest on in the Lord's bosom till we meet to part no more
                </p>
            
            </div>
        </div>
        <div className="rowe-child border-gray-700">
            <div className="rowe-item">
                <GiHastyGrave className="grave-name text-gray-100 bg-gray-700" />
                <h3>Basil Ebuzor</h3>
                <h6>Son</h6>
                <p>Who am I to complain when the creator of heaven and Earth has spoken. Who am I to question God,on the time and method he chooses on calling it's own. Not a day goes by that you are not missed. I thought I might get it easier as time went by, but it doesn't. I do not know how to come to terms with reality but I know you are the best mum a son can ask for in so many ways. Ezinne, I miss you;  your memory will forever remain in my heart. May your gentle soul continue to rest in the Lord's blossom. Amen.
                </p>
            
            </div>
        </div>
        <div className="rowe-child border-gray-700">
            <div className="rowe-item">
                <GiHastyGrave className="grave-name text-gray-100 bg-gray-700" />
                <h3>Victor Ebuzor</h3>
                <h6>Son</h6>
                <p>MAMA, Words  cannot Express the shock I hard on hearing the news about your death.So difficult to believe. IT has  always been my wish that you will live up to one hundred years & I never knew that it will end so fast, but in any case, the good lord knows why. 
                    Mama was a very strong  woman who believes that it is only through hard work that  any  body can have  a good success in life., A business woman who traded  on many things at a different times. A very surppotive woman who lived a life that is worthy of emulation.
                    My siblings and I including my kids will surely miss you. <br />
                    Nma rest on till that faithful morning when we shall meet to part no more.
                </p>
            
            </div>
        </div>
        <div className="rowe-child border-gray-700">
            <div className="rowe-item">
                <GiHastyGrave className="grave-name text-gray-100 bg-gray-700" />
                <h3>Innocent and Family</h3>
                <h6>Son</h6>
                <p> FAREWELL MY FIRST LOVE <br />
                Death is inevitable, it  is a supreme price every living being must pay. It is also a necessary end. But it is only God that knows the time and place. <br /> 
                WE ARE LIKE PENCIL IN THE HAND OF THE CREATOR <br /> 
                It's still very  hard for me to  believe and accept that you are no more. When we spoke during the new year I told you that I will be going to Abuja for a mission but once am back I will come and  see you at home ,you said is ok. While still in Abuja the news of your demise without Sickness came like a hurricane which am still battling to overcome. Then I remembered what you said when I brought CHIMADIKANME for you to  see in November last year. EVEN IF I SLEEP TODAY AND DIE IN MY SLEEP. I AM A FULFILLED GROUNDMOTHER.( May be you where waiting to carry my child which God never denied you) what then can I do than to take solace in the most high God. <br />
                NWANYI OWERRI like I fondly  call you was behind my success ,you thought me ENTREPRENEURSHIP from a tender age and that has kept me going till date. Your Devoted PRAYERS keeps making ways for me.
                You are too special and words are not enough to describe your worths and values to me. Rest on mama <br />
                You will forever be in our hearts. <br />
                </p>
            
            </div>
        </div>
        <div className="rowe-child border-gray-700">
            <div className="rowe-item">
                <GiHastyGrave className="grave-name text-gray-100 bg-gray-700" />
                <h3>Lambert Ebuzor and Family</h3>
                <h6>Son</h6>
                <p> The moment I received the news of your ascendance, MAMA, my world caved in. My heart was torn into two, one was filled with heartache and pain, the other died with you. Sleep eluded me. I lie awake at nights when the world is fast asleep, through the stillness of the dark; alone I take a walk down memory lane with tear filled eyes. <br />
                If I could write a story about you, dear mother, it would be the greatest ever told of a kind and warm heart, a loving and caring mother who had a generous heart of gold. Diction fail in providing the right words that would correctly describe your sterling qualities. The images fill my head but my education could not arm me with words to say how you are. <br /> 
                I could write a million pages yet unable to say just how much you gave yourself,  your love, your resources, your everything; how dedicated you were to caring for your children and others around you; the hope and succour God gave to the childless, and the homeless through you; the free advice you gave generously,  the home you gave to the homeless. You paid for education for those who could ill afford it; mediated between warring couples; your generous smile, your sincere advice and kind words that soothe all troubled souls around you, your profound belief that God in heaven watches over our actions. <br />
                If I could write a story about your life struggles, the needless troubles and battles you went through in defence of your children and your siblings and a huge heart full of sunshine with which you tolerated and forgave, a million pages would not be enough to tell the story of how simple a heart you were. <br />
                Adieu Mama, till we meet to part no more  
                </p>
            
            </div>
        </div>
        <div className="rowe-child border-gray-700">
            <div className="rowe-item">
                <GiHastyGrave className="grave-name text-gray-100 bg-gray-700" />
                <h3>Peace Emma Ebuzor</h3>
                <h6>Daugther-in-law</h6>
                <p> Tribute to my dear Mother Inlaw.   <br />
                Words will fail me to adequately explain the state of my despair on the news of your death. You meant so much to me, always encouraging me to continue to move on not minding the distractions in life. You had been there in all the period of trials to urge me to move on. Your wise counsels remained invaluable to me that I will always cherish. I will always look back to remember I had a mother inlaw that appreciated me irrespective of all odds. I miss you very dearly but take solace in the fact that you have played your role here on earth. Continue to rest in the Lord's bossom until we meet to part no more.  <br />
             Peace  Emma  Ebuzor. <br />
              First daughter inlaw
                </p>
            
            </div>
        </div>
        <div className="rowe-child border-gray-700">
            <div className="rowe-item">
                <GiHastyGrave className="grave-name text-gray-100 bg-gray-700" />
                <h3>Dr. Achugamonu Uzoma (FCA, ACIB)</h3>
                <h6>Nephew</h6>
                <p>Tribute to my loving mother! <br />
                    When I was told to write a brief tribute for my mum of inestimable value, I felt that wasn't fair enough because if given the opportunity I will write whole  book. My sweet mother was a mother  like no other woman who taught me from cradle that the reward for hardwork is more work. She is selfless,  uncompromising, loving, God fearing, caring, and a paragon of inner and outer beauty. Mama Adure never discriminated among her biological children and the rest of her other children from different mothers. This was so much so that I am always regarded as a member of the Ebuzor family from Umuagwa, Egbeada.<br />
                    How do I know that my discussions with you on the 4th of January, 2022 was our last meeting. How will I ever imagined that your prayer for me in January 2021 in the presence of your daughter was the last. Mama in January, 1994,  you blessed me, prayed for me and sent me forth to go and stay with your son in Lagos. Mama I want to tell you that  today that small boy you sent forth has grown to become a real man with several accomplishments to the glory of God. I therefore make this solemn declaration that I dedicate all my achievements posthumously to you for laying the right foundation which served as a springboard to attaining the enviable positions I occupy today. <br />
                    Mama sleep on until we meet on the ressurection morning where death shall no longer separate us.
                </p>
            
            </div>
        </div>
        <div className="rowe-child border-gray-700">
            <div className="rowe-item">
                <GiHastyGrave className="grave-name text-gray-100 bg-gray-700" />
                <h3>Ebuka & Chibuike Ebuzor</h3>
                <h6>Grandsons</h6>
                <p>Tribute to our loving grandmother! <br />
                The news of your death came to us as a rude shock. We had planned coming to pay a surprise visit after the holidays to come and see you, only for that news to cut short  our plans. Life cannot deliver all expectations at all times as we could not achieve this desire about you. We cherish the memories of your care and concern for us about our academics and growth in the service of God. We will miss you dearly but remain consoled that you have attained a higher plane in the  Lord's bossom .May the Lord keep you untill the resurrection morning where we will meet to part no more. 
                </p>
            
            </div>
        </div>
        <div className="rowe-child border-gray-700">
            <div className="rowe-item">
                <GiHastyGrave className="grave-name text-gray-100 bg-gray-700" />
                <h3>Nissi/Chimzy/ Chimaobim/Adaugo & Testimony Ebuzor</h3>
                <h6>Grandkids</h6>
                <p>Tribute to our loving grandmother! <br />
                Sweetest Grandma, You may have passed on, but your memories would always live on within us. Thank you for your sacrifices, your care and concern, your love and everything that you have done for us.  Wherever you are, we know you are in a much better place. We will be forever grateful and thankful that you were our  'grandmother'. Continue to rest in perfect peace. 
                </p>
            
            </div>
        </div>
        <div className="rowe-child border-gray-700">
            <div className="rowe-item">
                <GiHastyGrave className="grave-name text-gray-100 bg-gray-700" />
                <h3>Love Victor Ebuzor </h3>
                <h6>Daugther-in-law</h6>
                <p>My dear Mother-in-law,<br />
                You were the truest, dearest, mother-in-law I could ever have asked for. I called you a friend, adviser and also a mother. You were a precious gift from God, So much beauty, grace, love and patience you possessed. You touched my heart in so many ways, your strength and smile even on dark days made me realize I had an angel beside me. You were all I ever needed. God gave me the best mother-in-law in the world. I miss you my dearest mother-in-law. Your otherly love is irreplaceable, which makes losing you to death an incredibly painful experience.  <br />
                RIP Mama

                </p>
            
            </div>
        </div>
        <div className="rowe-child border-gray-700">
            <div className="rowe-item">
                <GiHastyGrave className="grave-name text-gray-100 bg-gray-700" />
                <h3>Cicilia's children </h3>
                <h6>Mephews </h6>
                <p>Tribute to a True Mother -  Mama Adaure Ebuzor <br />
                Real mother is the one that truly loves her children, who can do anything for her children. Exemplary mother, industrious mother, loving and generous mother. Mama Adaure, you were a real mother.  <br />
                Dee Adaure, you were not that skilled with pen and paper but your zeal, perseverance, hardworking and business skills combine with your roles as a mother and wife surpassed understanding and had been a legacy that you left for us. <br />
                Your truthfulness is our zeal, your principles are the reins of our life, your simplicity is our adornment. And your prayers, our existence.
                You worked hard and your achievements are profound. Your departure is a great loss. We love you but loves you most by taking you to him to come and have enternal rest. May the Almighty God grant you Enternal Rest (Amen).

                </p>
            
            </div>
        </div>
        <div className="rowe-child border-gray-700">
            <div className="rowe-item">
                <GiHastyGrave className="grave-name text-gray-100 bg-gray-700" />
                <h3>Ukawuike chidozie francis </h3>
                <h6>Nephew</h6>
                <p>Tribute to my loving mother .<br />
                Mama words can't express the news of your demise, but who am I to question our creator,who is the giver and taker of life.You loved and treated me like your Son all through the period I stayed with you while I was  in school,you taught me to be truthful and honest in everything I do,and above all to seek for peace with all men,I love you but God loves you most and I know you are in the Bossom of the Lord,you will forever be in my heart.<br />
                Adieu Mama

                </p>
            
            </div>
        </div>
        <div className="rowe-child border-gray-700">
            <div className="rowe-item">
                <GiHastyGrave className="grave-name text-gray-100 bg-gray-700" />
                <h3>Opara okechi</h3>
                <h6>Grandson</h6>
                <p>It was like a sharp arrow your death pierced through my heart and left it hurting. I wish I could tell myself that you will be back some day, that is my greatest wish right now. But I cannot change the way life goes Death is inevitable. Grandma, you are going to be forever missed but I know you are in heaven! Rest in peace grandma. I can't forget de name u use to call me when ever I come to the house (DOCTOR AMAMASI) I know you are smiling down on us from heaven. Please watch over us! Thinking of you till the day we meet again? 
                </p>
            
            </div>
        </div>
        <div className="rowe-child border-gray-700">
            <div className="rowe-item">
                <GiHastyGrave className="grave-name text-gray-100 bg-gray-700" />
                <h3>Lilian chidinma ukawuike </h3>
                <h6>Niece</h6>
                <p>Mama..... It's so sad to know I will never see you again, but I find solace knowing you are resting in Abraham's bosom.
                I really can not find words to express the grief, you are full of love and affection. Continue to rest in peace till we meet on the resurrection morning ....
                </p>
            
            </div>
        </div>
        <div className="rowe-child border-gray-700">
            <div className="rowe-item">
                <GiHastyGrave className="grave-name text-gray-100 bg-gray-700" />
                <h3>Agnes Ukawuike </h3>
                <h6>Sister-in-law</h6>
                <p>Mama Adure as I normally call you, you are such a wonderful sister-in-law. I don’t know it’s going to come so soon, I will miss you, in any case we can’t question God. Continue to rest in the blossom of the Lord until we meet to part no more. Jee nke oma,  nwayi oma. 

                </p>
            
            </div>
        </div>
        <div className="rowe-child border-gray-700">
            <div className="rowe-item">
                <GiHastyGrave className="grave-name text-gray-100 bg-gray-700" />
                <h3>Pauline  Orgaram</h3>
                <h6>Niece</h6>
                <p>My dear Mother-in-law,<br />
                I feel so heavy in my heart to accept the reality that you are no more,I could still remember  on that faithful day I came to see you,to inform you that my marriage which you prayed for was a success.you blessed me and wish me a happy marriage life.in less than an hour when we board a bus to travel with my husband,my phone rang the news I received was you have gone to meet your maker.Mama You are such wonderful mother that showed I and my siblings love and taught us how to live a good life.You gave us all the basic needs of life.Mama your death has created a vacuum in our heart,but we take solace in God.Mama continue to rest in the bossom of our God till we meet to part no more.  <br />
                Audiu mama.

                </p>
            
            </div>
        </div>
        {/* <div className="rowe-child border-gray-700">
            <div className="rowe-item">
                <GiHastyGrave className="grave-name text-gray-100 bg-gray-700" />
                <h3>Love Victor Ebuzor </h3>
                <h6>Daugther-in-law</h6>
                <p>My dear Mother-in-law,<br />
                You were the truest, dearest, mother-in-law I could ever have asked for. I called you a friend, adviser and also a mother. You were a precious gift from God, So much beauty, grace, love and patience you possessed. You touched my heart in so many ways, your strength and smile even on dark days made me realize I had an angel beside me. You were all I ever needed. God gave me the best mother-in-law in the world. I miss you my dearest mother-in-law. Your otherly love is irreplaceable, which makes losing you to death an incredibly painful experience.  <br />
                RIP Mama

                </p>
            
            </div>
        </div> */}


    </div>


<Footer />
</div>
  )
}

export default Tribute