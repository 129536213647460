import React, { useState } from 'react';
import backgroundImage from '../assets/funeralz.jpeg';
import welcome from '../assets/funeral13.jpeg';
import image1 from '../assets/funeral10.jpeg';
import image2 from '../assets/funeral11.jpeg';
import image3 from '../assets/funeral12.jpeg';
import image4 from '../assets/funeral13.jpeg';
import image5 from '../assets/funeral14.jpeg';
import image6 from '../assets/funeral15.jpeg';
import image7 from '../assets/funeral18.jpeg';
import image8 from '../assets/funeral17.jpeg';
// import image7 from '../assets/funeral16.jpeg';
import { GiHastyGrave } from 'react-icons/gi';
// import gericht from '../assets/gallery04.png';


import { BsInstagram, BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';

// import { SubHeading } from '../../components';


import './land.css';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
// import { images } from '../constants';

// const galleryImages = [gallery01, gallery02, gallery03, gallery04]

const images = [image3, image6, image1, image2, , image4, image5, image7, image8 ]


const Land = () => {


  const scrollRef = React.useRef(null);

  const scroll = (direction) => {
    const {current} = scrollRef;

    if (direction === 'left') {
      current.scrollLeft -=  300;
    } else {
      current.scrollLeft += 300;
    }
  }


  return (
    <div>
    <nav className='app__navbar bg-gray-400'>
        <Link to="/tributes">
            <button className=' bg-gray-700 text-gray-100 custom__button'>  Post a tribute</button>
        </Link>
    </nav>

    {/* nav area*/} 
    <div className="app__header app__wrapper section__padding bg-gray-400">
        <div className="app__wrapper_info">
        <h1 className="app__header-h1e mb-6 custom__buttonsd">With a sad and heavy heart</h1>
        <p className="p__opensans  text-black">We the entire family of EBUZOR in Umuagwa village of Egbeada Autonomous Community in Mbaitoli LGA of Imo state wish to invite you to join us as we pay the last respect to our departed Wife, Mother, Sister, Sister-in-law, Aunty & Grandmother</p>
        </div>
        <div className="app__wrapper_img app__header_img">
        <img src={backgroundImage} alt="Header Img" />
        </div>
    </div>

    {/* nav area*/}             
    <div className="app__header app__wrappers section__paddings hij" >
        <div className="app__wrapper_info">
        <h1 className="app__header-h1s">Adaure Sussana Ebuzor</h1>
        <p className="p__opensanss">BIOGRAPHY OF LATE EZINNE SUSSANA ADAURE CHIENYENWA EBUZOR. NEE (UKAWUIKE) </p>

        <p className="p__opensansss">
        Late Ezinne Susanna Adaure Ebuzor was born as the first daughter but the second child of Late Oha Gabriel Ayozieuwa Ukawuike of Umuibe Akwakuma in Owerri North Local Government and Nne Alice Nwachalu Ukawuike  Nee Ihewunezi of Umu Emege-Iheoma of Umudagu Mbieri in Mbaitoli Local Government , both in  Imo State of Nigeria.  <br />
        She grew up under the tutelage of her dutiful Mother Alice alongside others. She was initially sent to school but that effort to acquire formal education was truncated early enough because the father did not subscribe to her receiving corporal punishment out of the love he had for her. This initial effort was however compensated by the thorough home  training she received from the mother. In 1959, she married her husband the late Deacon Godwin Ebuzor whom she joined  later in Burutu in the present day Delta State where they lived till the outbreak of the hostilities that resulted in the civil war in Nigeria. <br />
        {/* FAMILY LIFE : <br /> */}
        Darling Mum, we will forever appreciate this sacrifice. After the war she  became  deeply involved in farming and combined it with trading. In the area of training the children,  She was infact a complete disciplinarian that never allowed any mischievous act go without commensurate punishment. The fear of Mama for us the children was the beginning of wisdom , so everyone was expected to behave. In the same way she was a very good motivator that encouraged hard work by rewarding same whenever desirable. She believed in  telling the truth at all times.
        She also loved education and encouraged us to pursue it as much as our ability could carry us. Doing well in school was one of the easiest ways to get whatever you wanted from her in addition to getting a special dish for the effort.    <br /> 
        Mama was a good Christian of the Catholic faith who believed and accepted the Lordship of Jesus over her life. She also belonged to the association of Women town union as well as the village and kindred meetings. She is a very strong member of Mgboto Azuanuka and Akwakuma daughters League. Her final moments came a few months after the death of her younger brother which wrecked her psychologically as they were too close. She gave up the Ghost giving assurance all was well and we know that our hope is built on nothing less than Jesus. Adieu great Mum till we meet on the resurrection morning. 

        </p>
        <p className="p__opensansss" style={{marginTop: "2rem", fontWeight: "700" }}> Wednesday April 20 <br />
<h6 className="edit" style={{marginTop: "1rem"}}>Body leaves Zanda Mortuary Nwaorubi to her country home Egbeada Autonomous Community in Mbaitoli LGA of Imo state <br /> Time: 8:00AM <br /> Lying in state at her residence <br /> Time: 9:00AM </h6>  <br /> 
<h6 className='edit' style={{marginTop: "1rem", marginBottom: "1.5rem"}} >Funeral Mass <br /> Time: 11:00AM <br /> Venue: St. Peter's Catholic church Egbeada in Mbaitoli LGA Imo state <br /> 
Interment follows Immediately after the church service at her country home.

</h6>
Sunday April 24. <br />
</p>

<h6 className='nein' style={{marginTop: "1rem", fontWeight: "500"}}>
    Outing Service <br /> Venue: St, Peter's Catholic Church Egbeada  <br /> Time: 9:00AM
</h6>

        </div>
        <div className="app__wrappers_img">
        <img src={welcome} alt="Header Img" />
        </div>
    </div>

    <div className="app__gallery bg-gray-400  flex__center">
        <div className="app__gallery-content">
            <h1 className="app__header-h1 custom__buttonsd">Photo Gallery</h1>
            <p className="p__opensans text-black" style={{ marginTop: "2rem", textTransform: "none"}}>  In order to commemorate the life and times of our beloved ADAURE you could upload any picture and all pictures you have of her by clicking the link button and also see pictures others post of her </p>
            <Link to="/">
                <button type='button' className="custom__buttons bg-gray-700 text-gray-100">Post a photo and see other photos</button>
            </Link>
        </div>

        <div className="app__gallery-images">
            <div className="app__gallery-images_container" ref={scrollRef}>
            {images.map((image, index) => (
                <div className="app__gallery-images_card flex__center" key={`gallery_image-${index+1}`}>
                <img src={image} alt="gallery" />
                </div>
            ))}
            </div>
            <div className="app__gallery-images_arrows">
            <BsArrowLeftShort className="gallery__arrow-icon arrow__left" onClick={() => scroll('left')} />
            <BsArrowRightShort className="gallery__arrow-icon" onClick={() => scroll('right')} />
            </div>
        </div>
    </div>

    {/**  Pay a tribute */}

    <div className="app__header app__wrapped section__paddingss hij">
        <div className="contained__headings">
            <h1 className="app__header-h1d">Want to pay a tribute such as those below?</h1>
            <p className="p__opensand">Send an <a className="email bg-gray-700 text-gray-100" href={"mailto: tributes@ebuzor.com" }>email</a> to be reviewed and posted on the tributes page</p>
        </div>

        <div className="tribute-display">
            <div className="rowe">
                <div className="rowe-child border-gray-700">
                    <div className="rowe-item">
                        <GiHastyGrave className="grave-name bg-gray-700 text-gray-100" />
                        <h3>Chinonye Emmanuel Ebuzor</h3>
                        <h6>Son</h6>
                        <p> You were a wonderful gift of love I was privileged to have as a mother. A rare gift from God that was given to impact lives on Earth in so many ways. You stood out like a collossus in every area both in your family  and business life. You effectively combined your trading business, farming and the task of training of us both your biological children and the others without much stress .You trained us your children with the fear of God making us to imbibe the virtues of honesty , hard work, courage  and to accept the will of God in every area of our lives. You were there to help the needy encourage the less privileged and give succour to the helpless. You stood in the gap so strongly that our father's absence was not so strongly felt when it came to paying our school fees when he was away on business trips and could not be reached immediately. You made so much sacrifice that paid off in various areas in our lives that when children were suffering from serious malnutrition during the cilvil war those of us around had sufficient nutrients that shielded us from the dreaded disease. Your love for me and my family will not be forgotten in a hurry. I will also not forget your able demonstration of a strong character in all you did. You stood to be counted for the truth not minding whose ox is goored. You were not attracted to material things when it mattered to say the truth and you made me to toe that line and I am happy I learnt this rope from you. I will not forget your strong sense of humour and witty presentation of matters in a hurry. I will forever be very grateful to God for giving me a mother like you. I miss you greatly dearest Mum. Rest on in the Lord's bosom till we meet to part no more </p>
                      
                    </div>
                </div>
                <div className="rowe-child border-gray-700">
            <div className="rowe-item">
                <GiHastyGrave className="grave-name text-gray-100 bg-gray-700" />
                <h3>Basil Ebuzor</h3>
                <h6>Son</h6>
                <p>Who am I to complain when the creator of heaven and Earth has spoken. Who am I to question God,on the time and method he chooses on calling it's own. Not a day goes by that you are not missed. I thought I might get it easier as time went by, but it doesn't. I do not know how to come to terms with reality but I know you are the best mum a son can ask for in so many ways. Ezinne, I miss you;  your memory will forever remain in my heart. May your gentle soul continue to rest in the Lord's blossom. Amen.
                </p>
            
            </div>
                </div>
                <div className="rowe-child border-gray-700">
                    <div className="rowe-item">
                        <GiHastyGrave className="grave-name text-gray-100 bg-gray-700" />
                        <h3>Victor Ebuzor</h3>
                        <h6>Son</h6>
                        <p>MAMA, Words  cannot Express the shock I hard on hearing the news about your death.So difficult to believe. IT has  always been my wish that you will live up to one hundred years & I never knew that it will end so fast, but in any case, the good lord knows why. 
                            Mama was a very strong  woman who believes that it is only through hard work that  any  body can have  a good success in life., A business woman who traded  on many things at a different times. A very surppotive woman who lived a life that is worthy of emulation.
                            My siblings and I including my kids will surely miss you. <br />
                            Nma rest on till that faithful morning when we shall meet to part no more.
                        </p>
                    
                    </div>
                </div>
                <div className="rowe-child border-gray-700">
                    <div className="rowe-item">
                        <GiHastyGrave className="grave-name text-gray-100 bg-gray-700" />
                        <h3>Innocent and Family</h3>
                        <h6>Son</h6>
                        <p> FAREWELL MY FIRST LOVE <br />
                        Death is inevitable, it  is a supreme price every living being must pay. It is also a necessary end. But it is only God that knows the time and place. <br /> 
                        WE ARE LIKE PENCIL IN THE HAND OF THE CREATOR <br /> 
                        It's still very  hard for me to  believe and accept that you are no more. When we spoke during the new year I told you that I will be going to Abuja for a mission but once am back I will come and  see you at home ,you said is ok. While still in Abuja the news of your demise without Sickness came like a hurricane which am still battling to overcome. Then I remembered what you said when I brought CHIMADIKANME for you to  see in November last year. EVEN IF I SLEEP TODAY AND DIE IN MY SLEEP. I AM A FULFILLED GROUNDMOTHER.( May be you where waiting to carry my child which God never denied you) what then can I do than to take solace in the most high God. <br />
                        NWANYI OWERRI like I fondly  call you was behind my success ,you thought me ENTREPRENEURSHIP from a tender age and that has kept me going till date. Your Devoted PRAYERS keeps making ways for me.
                        You are too special and words are not enough to describe your worths and values to me. Rest on mama <br />
                        You will forever be in our hearts. <br />
                        </p>
                    
                    </div>
                </div>
                <div className="rowe-child border-gray-700">
                    <div className="rowe-item">
                        <GiHastyGrave className="grave-name text-gray-100 bg-gray-700" />
                        <h3>Lambert Ebuzor and Family</h3>
                        <h6>Son</h6>
                        <p> The moment I received the news of your ascendance, MAMA, my world caved in. My heart was torn into two, one was filled with heartache and pain, the other died with you. Sleep eluded me. I lie awake at nights when the world is fast asleep, through the stillness of the dark; alone I take a walk down memory lane with tear filled eyes. <br />
                        If I could write a story about you, dear mother, it would be the greatest ever told of a kind and warm heart, a loving and caring mother who had a generous heart of gold. Diction fail in providing the right words that would correctly describe your sterling qualities. The images fill my head but my education could not arm me with words to say how you are. <br /> 
                        I could write a million pages yet unable to say just how much you gave yourself,  your love, your resources, your everything; how dedicated you were to caring for your children and others around you; the hope and succour God gave to the childless, and the homeless through you; the free advice you gave generously,  the home you gave to the homeless. You paid for education for those who could ill afford it; mediated between warring couples; your generous smile, your sincere advice and kind words that soothe all troubled souls around you, your profound belief that God in heaven watches over our actions. <br />
                        If I could write a story about your life struggles, the needless troubles and battles you went through in defence of your children and your siblings and a huge heart full of sunshine with which you tolerated and forgave, a million pages would not be enough to tell the story of how simple a heart you were. <br />
                        Adieu Mama, till we meet to part no more  
                        </p>
                    
                    </div>
                </div>
      
     
            </div>

        </div>

        <Link to="/tribute">
                <button type='button' className="custom__buttoned bg-gray-700 text-gray-100">Tributes Page</button>
        </Link>
    </div>

  <Footer />
    </div>
  )
}

export default Land