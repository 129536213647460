import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { RiHomeFill } from 'react-icons/ri';
import { IoIosArrowForward } from  'react-icons/io';

import logo from '../assets/logo.png'
import { categories } from '../utils/data';

const isNotActiveStyle = 'flex items-center px-5 gap-3 text-zinc-100 hover:text-black transition-all duration-200 ease-in-out capitalize';
const isActiveStyle = 'flex items-center px-5 gap-3 text-zinc-300 border-r-2 border-white transition-all duration-200 ease-in-out capitalize';



const Sidebar = ({ user, closeToggle }) => {

    const handleCloseSidebar = () =>{
        if(closeToggle) closeToggle(false)
    }

  return (
    <div className="flex flex-col justify-between bg-gray-700 h-full overflow-y-scroll min-w-210 hide-scrollbar">

        <div className="flex flex-col">
            <Link
                to="/"
                className="flex px-5 gap-2 my-6 pt-1 w-190 text-gray-900 items-center"
                onClick={handleCloseSidebar}
            >
                <img src={logo} alt="logo" className="w-full"  />
            </Link>
            <div className="flex flex-col gap-5">
                <NavLink
                    to="/"
                    className={({ isActive }) => isActive ? isActiveStyle : isNotActiveStyle}
                    onClick={handleCloseSidebar}
                >
                    <RiHomeFill />
                    Home
                </NavLink>
                <h3 className="mt-2 px-5 text-base text-zinc-100 2xl:text-xl">Discover Categories</h3>
                {categories.slice(0, categories.length - 1).map((category) => (
                    <NavLink 
                        to={`/category/${category.name}`}
                        className={({ isActive }) => isActive ? isActiveStyle : isNotActiveStyle}
                        onClick={handleCloseSidebar}
                        key={category.name}
                    >
                        <img src={category.image} className="w-8 h-8 rounded-full shadow-sm" alt="category" />
                        {category.name}
                    </NavLink>
                ))}
            </div>

            <Link to='/login'>
            <div className='flex my-5 gap-2 mb-3 p-2 items-center bg-white rounded-lg shadow-lg mx-3'>
                Post your own Pictures
            </div>
        </Link>
        </div>

        {user && (
            <Link
                to={`user-profile/${user._id}`}
                className="flex my-5 gap-2 mb-3 p-2 items-center bg-white rounded-lg shadow-lg mx-3"
                onClick={handleCloseSidebar}
            >
                <img src={user.image} alt="user-profile" className="w-8 h-8 rounded-full" />
                <p>{user.userName}</p>
            </Link>
        )}        
    </div>
  )
}

export default Sidebar