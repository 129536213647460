import React, { useEffect } from 'react';
import { Routes , Route , useNavigate} from 'react-router-dom';
import Login from './components/Login';
import Home from './container/Home';
import Land from './container/Land';

import './app.css';
import { fetchUser } from './utils/fetchUser';
import Tributes from './container/Tributes';
import Tribute from './container/Tribute';

const App = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const user = fetchUser();
    if(!user) navigate('/home');
  }, [])
  
  return (
    
    
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="tribute" element={<Tribute />} />
      <Route path="tributes" element={<Tributes />} />
      <Route path="/*" element={<Home />} />
      <Route path="home" element={<Land />} />
    </Routes>
  );
}

export default App;
