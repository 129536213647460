import React from 'react'

const Footer = () => {
  return (
    <div className='text-gray-700 text-center p-4'>
          © 2022 <a target='_blank' className='text-blue-800' href="https://www.bexoni.com/">Bexoni Digital</a> all right reserved
    </div>
  )
}

export default Footer